.booksNew {
    display: flex;
    flex-direction: column;
    // width: 180px;
    text-align: left;
    align-items: center;
    position: relative;

    .displayImage {
        height: 200px;
        width: 100%;
        border-radius: 3px;

        &:hover {
            cursor: pointer;
        }
    }

    .textContentNew {
        font-weight: 600;
        font-size: 16px;
        width: 100%;
        padding-top: 10px;
    }

    .textContentName {
        font-weight: 500;
        font-size: 16px;
        width: 100%;
        padding-top: 5px;
    }

    .textContentAuthor {
        font-weight: 500;
        font-size: 16px;
        width: 100%;
        color: black;
    }

    .disableAddCart {
        background-color: #52b690 !important;
        cursor: not-allowed !important;
    }

    .addToCart {
        display: flex;
        flex-direction: row;
        height: 36px;
        width: 70px;
        align-items: center;
        justify-content: center;
        position: absolute;
        background-color: #52B640;
        border-radius: 8px;
        cursor: pointer;
        color: white;
        margin-top: 10px;
        margin-left: 80px;
        padding: 8px;
        gap: 8px;



        &:hover {
            background-color: green;
            border-radius: 8px;
            cursor: pointer;
        }
    }

    .booksTopBar {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        // gap:8px;
        height: 56px;
        align-items: center;
        padding-right: 8px;

    }

    .textDiv {
        display: flex;
        flex-direction: column;
        padding: 16px;

        .textContent {
            font-weight: 500;
            font-size: 16px;
            color: darkblue
        }
    }


}


.booksBottomBar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    gap: 8px;
    padding-top: 16px;

    .editButton {
        display: flex;
        flex-direction: row;
        height: 36px;
        width: 80px;
        align-items: center;
        justify-content: center;
        background-color: #52B640;
        border-radius: 8px;
        cursor: pointer;
        color: white;
        padding: 8px;
        gap: 8px;



        &:hover {
            background-color: green;
            border-radius: 8px;
            cursor: pointer;
        }


    }

    .deleteButton {
        display: flex;
        flex-direction: row;
        height: 36px;
        width: 100px;
        align-items: center;
        justify-content: center;
        background-color: red;
        border-radius: 8px;
        cursor: pointer;
        color: white;
        padding: 8px;
        gap: 8px;



        &:hover {
            background-color: darkred;
            border-radius: 8px;
            cursor: pointer;
        }

    }
}

.displayBooksOverall {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 180px;
    min-width: 180px;
}


.modalBook {
    display: flex;
    flex-direction: column;
    padding: 36px;

    .bookNameAuthor {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 16px;

        .textDiv {
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-top: 20px;
        }
    }

    .displayImageModal {
        height: 150px;
        width: 100px;
        border-radius: 3px;


    }


}


.slideIn .modal-dialog {
    transition: transform 1s ease-out !important;
}

.slideIn .modal-content {
    border-radius: 8px !important;
}



.allBooks {
    display: flex;
    flex-direction: column;
    gap: 32px;
    overflow-y: auto;
    padding: 16px 32px;

    .categoryBookDiv {
        display: flex;
        flex-direction: column;
        gap: 16px;
        position: relative;

        .categoryBook {
            display: flex;
            flex-direction: row;
            gap: 50px;
            margin: 0 auto;
            width: 100%;
            overflow-x: auto;
            position: relative;
            // align-items: center;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .categoryTitle {
            display: flex;
            flex-direction: row;
            text-align: left;
            font-weight: 500;
            font-size: 18px;
        }
    }


    .booksDiv {
        display: flex;
        flex-direction: row;
        gap: 50px;
        margin: 0 auto;
        width: 100%;
        padding: 36px;
        flex-wrap: wrap;
    }
}

.allBooksManage {

    display: flex;
    flex-direction: row;
    gap: 50px;
    margin: 0 auto;
    width: 100%;
    padding: 36px;
    flex-wrap: wrap;



}


.arrow {
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    cursor: pointer;
    text-align: center;
    line-height: 30px;
    font-size: 20px;
    opacity: 0;
    transition: opacity 0.3s;
    height: 40px;
    width: 40px;
    background-color: whitesmoke;
    display: flex;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
}

.arrowsIcon {
    height: 30px;
    width: 30px;
}

.arrow.visible {
    opacity: 1;
}

.arrow-left {
    left: 32px;
}

.arrow-right {
    right: 32px;
}