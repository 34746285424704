.cartItem {
    display: flex;
    flex-direction: row;
    width: 528px;

    border: 1px solid #c6c8CE;
    border-radius: 8px;

    .cartNameAuthor {
        display: flex;
        flex-direction: column;
        width: 300px;
        padding: 16px;
        justify-content: center;

        .name {

            font-size: 14px;
            font-weight: 500;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .author {
            font-weight: 400;
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .formDiv {
        display: flex;
        align-items: center;
        padding: 16px;
        justify-content: center;

        .form-control {
            width: 70px;
            // margin-left: 100px;
            margin-bottom: unset;
        }
    }

    .cartEditPrice {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        font-weight: 500;



    }

    .removeDiv {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .removeItem {
            display: flex;
            flex-direction: row;
            justify-content: center;
            height: 36px;
            width: 36px;
            align-items: center;
            margin-right: 8px;

            &:hover {
                background-color: #c6c8CE;
                border-radius: 8px;
            }

        }
    }



}