.topBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 56px;
    border-bottom: 1px solid #C6C8CE;
    padding: 0px 36px 0px 36px;
    align-items: center;

    .leftBar {
        display: flex;
        flex-direction: row;
        gap: 24px;
        align-items: center;

        .barsIcon {

            height: 30px;
            width: 30px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 4px;
            background-color: white;
            border: none;
            color: black;

            &:hover {
                background-color: #E8E6E3;
                border-radius: 8px;
            }
        }

        .dropdown-toggle::after {
            display: none;
        }

    }


    .imgContent {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;

        img {
            height: 22px;
            width: 36px;
            border-radius: 4px;

        }

        .content {
            font-family: 'Inter', sans-serif;
            font-size: 20px;
            font-weight: 600;
            color: black;

        }
    }

    .rightBar {
        display: flex;
        flex-direction: row;
        gap: 8px;
    }


}

.offcanvas {
    width: 600px;
    padding: 36px;

    .offcanvas-header {
        padding: unset;
        margin-bottom: 36px;
    }

    Button {
        margin-top: 36px;
    }

    .form-control {
        margin-bottom: 16px;
    }
}

.addButton {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: center;
    border: 1px solid #C6C8CE;
    background-color: #f3f4f5;
    color: black;
    font-weight: 500;
    font-size: 14px;
    border-radius: 8px;

    &:hover {
        background-color: #eaebee !important;
        border: 1px solid #c6c8ce !important;
        border-radius: 8px !important;
        cursor: pointer;
        color: black;
    }

    &:active {
        background-color: #eaebee !important;
        border: 1px solid #c6c8ce !important;
        border-radius: 8px !important;
        cursor: pointer;
        color: black !important;
    }

    &:disabled {
        border: 1px solid #C6C8CE;
        background-color: #f3f4f5;
        color: black;
        font-weight: 500;
        font-size: 14px;
        border-radius: 8px;
        opacity: 0.65;
    }
}


.saveButton {
    display: flex;
    flex-direction: row;
    background-color: #234fdc !important;
    border-radius: 8px !important;
    color: #fff !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    height: 36px !important;

    &:hover {
        border-color: #0a58ca;
        cursor: pointer;
    }

    &:active {
        border-color: #0a58ca;
        cursor: pointer;
    }

    &:disabled {
        opacity: 0.65;
    }
}