.manageBooks {
    display: flex;
    flex-direction: column;


    .manageBooksTitle {
        font-size: 18px;
        font-weight: 500;
        color: black;
        justify-content: left;
        display: flex;
        padding-top: 36px;
        padding-left: 36px;
    }
}